@import 'variables';

button {
  &:focus {
    box-shadow: 0 0 0 0.2rem var(--secondary-gold);
  }
}

div {
  &:focus {
    box-shadow: 0 0 0 0.2rem var(--secondary-gold);
  }
}

td {
  &:focus {
    box-shadow: 0 0 0 0.2rem var(--secondary-gold);
  }
}

li {
  &:focus {
    box-shadow: 0 0 0 0.2rem var(--secondary-gold);
  }
}

@supports selector(:focus-visible) {
  div,
  td,
  button,
  li {
    &:focus {
      box-shadow: none;
    }
  }
}

div,
td,
button,
li {
  &:focus-visible {
    box-shadow: 0 0 0 0.2rem var(--accent-400);
  }
}

.recharts-tooltip-wrapper {
  &:focus {
    box-shadow: none !important;
  }

  &:focus-visible {
    box-shadow: none !important;
  }
}
