@import 'variables';
@import 'animations';
@import 'custom';
@import 'focus';
@import 'date-picker';
@import 'scrollbar';
@import '../../node_modules/chartist/dist/index.css';
@import '../../node_modules/react-toastify/dist/ReactToastify.css';
@import '../../node_modules/@vime/core/themes/default.css';
@import '../../node_modules/react-big-calendar/lib/css/react-big-calendar.css';

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  overflow: hidden;
  padding: 0;

  .modal {
    pointer-events: none;
    z-index: 2;
  }

  .modal__container {
    background: none;
    pointer-events: none;
  }
}

* {
  box-sizing: border-box;
  outline: 0;
}

#root {
  background-color: #f3f4f6;
  flex: 0 0 auto;
  min-height: 100vh;
  width: 100%;
}

/* stylelint-disable-next-line selector-id-pattern */
#roomContainer > .OT_publisher {
  border-radius: 10px;
  position: absolute;
  right: 25px;
  top: 25px;
}
