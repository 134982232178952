@import 'variables';

::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}

::-webkit-scrollbar-track {
  background: none;
}

::-webkit-scrollbar-thumb {
  background: var(--primary-dark-grey);
  border-radius: 8px;
}

.rsw_2f {
  display: none;
}

.rsw_3G {
  display: block;
}
