.multi-column {
  .increment-panel-wrapper {
    flex-direction: column;
  }

  .action-buttons {
    flex-direction: row;

    > * {
      flex: 1;
    }

    > button:first-child {
      border-left: 0;
      border-top: 1px solid rgb(0 0 0 / 30%) !important;
    }
  }
}
