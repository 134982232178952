.journal-date-picker {
  &.react-calendar {
    width: 100%;
    z-index: 2000;
  }

  &.loading {
    pointer-events: none;
  }

  .react-calendar__month-view {
    margin-bottom: 1rem;

    .react-calendar__tile {
      align-items: center;
      display: flex;
      height: 48px;
      justify-content: center;
      line-height: normal;

      abbr {
        border-radius: 24px;
        display: block;
        font-size: 12px;
        font-weight: 500;
        height: 36px;
        padding: 12px;
        transition: all 0.2s ease;
        width: 36px;
      }

      &:enabled:hover {
        background-color: transparent;

        abbr:hover {
          background-color: #ede7f6;
          color: #673ab7;
        }
      }

      &.title__out_of_range {
        background-color: transparent;

        abbr {
          background-color: transparent;
          color: #909090;
        }
      }

      &.title__future {
        background-color: transparent;

        abbr {
          background-color: transparent;
          color: #000;
        }
      }

      &.tile__filled abbr {
        background-color: #e8f5e9;
        color: #1b5e20;
      }

      &.tile__not__filled abbr {
        background-color: #feebee;
        color: #b71c1c;
      }

      &.react-calendar__tile--now {
        background-color: transparent;

        abbr {
          background-color: #ede7f6;
          background-image: url('../assets/journaling/purple-star.svg');
          background-position: center;
          background-repeat: no-repeat;
          font-size: 0;
        }
      }

      &.react-calendar__tile--active {
        background-color: transparent;

        abbr {
          background-color: #673ab7;
          color: #fff;
        }

        &.tile__filled abbr {
          background-color: #1b5e20;
        }
      }

      &.react-calendar__tile--now.tile__filled abbr {
        background-color: #e8f5e9;
        background-image: url('../assets/journaling/green-star.svg');
      }

      &.react-calendar__tile--active.react-calendar__tile--now {
        abbr {
          background-color: #673ab7;
          background-image: url('../assets/journaling/white-star.svg');
        }

        &.tile__filled abbr {
          background-color: #1b5e20;
        }
      }
    }
  }
}

.coaching-date-picker {
  &.react-calendar {
    border: 0;
    width: 350px;
    z-index: 2000;
  }

  &.loading {
    pointer-events: none;
  }

  .react-calendar__navigation {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1rem;

    button {
      background: transparent;
      border: 0;
      color: #7d7d7d;
      font-size: 1.6rem;
    }

    .react-calendar__navigation__label {
      pointer-events: none;
    }

    .react-calendar__navigation__label__labelText--from {
      color: #000;
      font-size: 1.1rem;
      font-weight: 500;
    }

    .react-calendar__navigation__prev2-button,
    .react-calendar__navigation__next2-button {
      display: none;
    }
  }

  .react-calendar__month-view {
    .react-calendar__month-view__weekdays__weekday {
      text-align: center;

      abbr {
        font-size: 12px;
        font-weight: 500;
        text-decoration: none;
      }
    }

    .react-calendar__tile {
      align-items: center;
      border: 0;
      display: flex;
      height: 48px;
      justify-content: center;
      line-height: normal;

      abbr {
        border-radius: 24px;
        display: block;
        font-size: 12px;
        font-weight: 500;
        height: 36px;
        padding: 12px;
        transition: all 0.2s ease;
        width: 36px;
      }

      &:enabled:hover {
        background-color: transparent;

        abbr:hover {
          background-color: #ede7f6;
          color: #673ab7;
        }
      }

      &.title__future {
        background-color: transparent;

        abbr {
          background-color: transparent;
          color: #000;
        }
      }

      &.title__completed abbr {
        background-color: #e8f5e9;
        color: #1b5e20;
      }

      &.title__attempted abbr {
        background-color: #feebee;
        color: #b71c1c;
      }

      &.title__incomplete abbr {
        background-color: #e8d57e75;
        color: #a18716;
      }

      &.title__disabled {
        background-color: transparent;

        abbr {
          color: #000;
        }
      }

      &.react-calendar__tile--now {
        background-color: transparent;

        abbr {
          background-color: #ede7f6;
          background-image: url('../assets/journaling/purple-star.svg');
          background-position: center;
          background-repeat: no-repeat;
          font-size: 0;
        }
      }

      &.react-calendar__tile--active {
        background-color: transparent;

        abbr {
          background-color: #673ab7;
          color: #fff;
        }

        &.tile__filled abbr {
          background-color: #1b5e20;
        }
      }

      &.react-calendar__tile--now.tile__filled abbr {
        background-color: #e8f5e9;
        background-image: url('../assets/journaling/green-star.svg');
      }

      &.react-calendar__tile--active.react-calendar__tile--now {
        abbr {
          background-color: #673ab7;
          background-image: url('../assets/journaling/white-star.svg');
        }

        &.tile__filled abbr {
          background-color: #1b5e20;
        }
      }
    }
  }
}
